/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
const TWO_DIGIT = '2-digit' as const;
const NUMERIC = 'numeric' as const;
const NARROW = 'narrow' as const;
const SHORT = 'short' as const;
const LONG = 'long' as const;

const formats = {
  types: {TWO_DIGIT, NUMERIC, NARROW, SHORT, LONG},

  // Examples of each date format are for '2018-1-31 22:07:15' time. Please, add example if you adding new format
  date: {
    //  1/31/2018
    l: {
      day: NUMERIC,
      month: NUMERIC,
      year: NUMERIC,
    },
    //  1/31/2018, 10:07 PM
    l_h_mm: {
      day: NUMERIC,
      month: NUMERIC,
      year: NUMERIC,
      hour: TWO_DIGIT,
      minute: TWO_DIGIT,
      hourCycle: 'h12',
    },
    //  1/31/2018, 13:07
    l_HH_mm: {
      day: NUMERIC,
      month: NUMERIC,
      year: NUMERIC,
      hour: TWO_DIGIT,
      minute: TWO_DIGIT,
      hourCycle: 'h23',
    },
    //  01/31/2018
    L: {
      day: TWO_DIGIT,
      month: TWO_DIGIT,
      year: NUMERIC,
    },
    //  01/31/2018, 22:07
    L_HH_mm: {
      day: TWO_DIGIT,
      month: TWO_DIGIT,
      year: NUMERIC,
      hour: TWO_DIGIT,
      minute: TWO_DIGIT,
      hourCycle: 'h23',
    },
    //  January 31, 2018
    LL: {
      month: LONG,
      day: NUMERIC,
      year: NUMERIC,
    },
    //  01/31/2018, 22:07:15
    L_HH_mm_ss: {
      day: TWO_DIGIT,
      month: TWO_DIGIT,
      year: NUMERIC,
      hour: TWO_DIGIT,
      minute: TWO_DIGIT,
      second: TWO_DIGIT,
      hourCycle: 'h23',
    },
    //  01/31/2018, 10:07:15 PM
    L_hh_mm_ss: {
      day: TWO_DIGIT,
      month: TWO_DIGIT,
      year: NUMERIC,
      hour: TWO_DIGIT,
      minute: TWO_DIGIT,
      second: TWO_DIGIT,
      hourCycle: 'h12',
    },
    //  22:07:15
    HH_mm_ss: {
      hour: TWO_DIGIT,
      minute: TWO_DIGIT,
      second: TWO_DIGIT,
      hourCycle: 'h23',
    },
    //  10:07:15 PM
    hh_mm_ss: {
      hour: TWO_DIGIT,
      minute: TWO_DIGIT,
      second: TWO_DIGIT,
      hourCycle: 'h12',
    },
    //  22:07
    HH_mm: {
      hour: TWO_DIGIT,
      minute: TWO_DIGIT,
      hourCycle: 'h23',
    },
    //  10:07 PM
    h_mm: {
      hour: TWO_DIGIT,
      minute: TWO_DIGIT,
      hourCycle: 'h12',
    },
    //  07:15
    m_ss: {
      minute: NUMERIC,
      second: TWO_DIGIT,
    },
    //  10:07:15 PM
    time: {
      hour: NUMERIC,
      minute: NUMERIC,
      second: NUMERIC,
    },
  },

  number: {
    // Format for USD, if variable in message string setted as {price, number, USD}
    USD: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
      useGrouping: true,
      minimumFractionDigits: 0,
    },

    // Byte formats
    bytes: {
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow',
      maximumFractionDigits: 0,
    },
    kilobytes: {
      style: 'unit',
      unit: 'kilobyte',
      unitDisplay: 'narrow',
      maximumFractionDigits: 0,
    },
    megabytes: {
      style: 'unit',
      unit: 'megabyte',
      unitDisplay: 'narrow',
      maximumFractionDigits: 0,
    },
    gigabytes: {
      style: 'unit',
      unit: 'gigabyte',
      unitDisplay: 'narrow',
      maximumFractionDigits: 0,
    },
    terabytes: {
      style: 'unit',
      unit: 'terabyte',
      unitDisplay: 'narrow',
      maximumFractionDigits: 0,
    },
    petabytes: {
      style: 'unit',
      unit: 'petabyte',
      unitDisplay: 'narrow',
      maximumFractionDigits: 0,
    },
  },
} as const;

// add index signature type to support indexing for unspecified properties
export default formats as typeof formats & {[k: string]: unknown};
